import Image from "next/image";

const SpecialOffersNewCard = ({ flight, handleClickCards }) => {
  return (
    <div
      onClick={() => handleClickCards(flight)}
      className={
        "group max-w-sm overflow-hidden rounded-lg bg-white shadow-md hover:shadow-lg transition-shadow cursor-pointer relative"
      }
    >
      <div className="p-[2px] px-4 rounded-br-lg bg-green-600 absolute top-0 left-0 z-10 text-white text-[11px] font-medium">
        Hamsing
      </div>
      {/* Image Container */}
      <div className="relative h-52 overflow-hidden">
        <Image
          src={flight.image || "/placeholder.svg?height=200&width=400"}
          alt="Destination Image"
          fill
          className="object-cover transition-transform duration-300 group-hover:scale-105"
          priority
        />
      </div>

      {/* Content */}
      <div className="p-4">
        <div className="flex items-start justify-between gap-4">
          {/* Route and Date */}
          <div>
            <h3 className="font-medium text-gray-900 text-lg capitalize">
              {flight.route.origin.city} to {flight.route.destination.city}
            </h3>
            <p className="text-sm text-gray-500 mt-1">
              {new Date(flight.schedules[0].departureTime).toLocaleDateString(
                "en-GB",
                {
                  weekday: "short",
                }
              )}
              ,{" "}
              {new Date(flight.schedules[0].departureTime).toLocaleDateString(
                "en-GB",
                {
                  day: "numeric",
                  month: "long",
                }
              )}
            </p>
          </div>

          {/* Price */}
          <div className="text-xl font-semibold text-green-700">
            BDT {flight.price.toLocaleString()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpecialOffersNewCard;
