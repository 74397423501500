/* eslint-disable react-hooks/exhaustive-deps */
"use client";
import React, { useState, useEffect, useRef } from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setTravelerCounts } from "@/redux/itinerarySlice";

const TravelarCounter = () => {
  const dispatch = useDispatch();
  const travelerCounts = useSelector(
    (state) => state.itineraries.travelerCounts
  );

  const [isVisibleTraveler, setIsVisibleTraveler] = useState(false);
  const [travelerData, setTravelerData] = useState(travelerCounts);

  const totalTraveler = Object.values(travelerData).reduce(
    (acc, count) => acc + count,
    0
  );

  useEffect(() => {
    if (JSON.stringify(travelerCounts) !== JSON.stringify(travelerData)) {
      dispatch(setTravelerCounts(travelerData));
    }
  }, [travelerData, dispatch, travelerCounts]);
  
  const handleTravelerVisibility = () => {
    setIsVisibleTraveler((prevState) => !prevState);
  };

  const updateTravelerCount = (type, increment) => {
    setTravelerData((prevData) => {
      let newCount = prevData[type] + increment;

      if (type === "adults" && newCount < 1) {
        toast.info("At least one adult traveler is required.");
        return prevData;
      }

      if (
        newCount < 0 ||
        (totalTraveler >= 9 && increment > 0) ||
        (type === "infants" && newCount > prevData.adults)
      ) {
        toast.error(
          `Cannot ${increment > 0 ? "increment" : "decrement"} ${type}!`
        );
        return prevData;
      }

      return { ...prevData, [type]: newCount };
    });
  };

  const handleInputChange = (type, value) => {
    const parsedValue = parseInt(value, 10);
    setTravelerData((prevData) => {
      const newCount = isNaN(parsedValue)
        ? prevData[type]
        : Math.max(0, parsedValue);
      if (type === "adults" && newCount < 1) {
        toast.info("At least one adult traveler is required.");
        return prevData;
      }
      return { ...prevData, [type]: newCount };
    });
  };

  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleClickOutside = (event) => {
        if (
          !dropdownRef.current?.contains(event.target) &&
          !buttonRef.current?.contains(event.target)
        ) {
          setIsVisibleTraveler(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, []);

  return (
    <div className="relative w-full md:w-auto">
      <div
        ref={buttonRef}
        className="md:w-full flex md:bg-[#e1eee8] bg-green-700/15 text-black text-sm md:text-xs items-center justify-center py-1.5 md:py-1 px-3 rounded-xl md:rounded-xl cursor-pointer"
        onClick={handleTravelerVisibility}
      >
        <div>
          {totalTraveler} Traveler{totalTraveler > 1 ? "s" : ""}
        </div>
        <MdOutlineKeyboardArrowDown fontSize={20} />
      </div>

      {isVisibleTraveler && (
        <div
          ref={dropdownRef}
          className="p-2 mx-auto bg-white text-black border rounded-lg shadow-lg w-full md:w-64 absolute left-0 top-10 z-10"
        >
          {["adults", "children", "kids", "infants"].map((type, index) => {
            const label = type.charAt(0).toUpperCase() + type.slice(1);
            const minLabel =
              type === "children" ? " (5-12)" : type === "kids" ? " (2-5)" : "";
            const isDisabled = totalTraveler >= 9;

            return (
              <div
                key={index}
                className="flex justify-between mb-2 border-b last:border-none"
              >
                <div className="text-start">
                  <label className="block text-sm text-black">
                    {label} {minLabel}
                  </label>
                </div>
                <div className="flex items-center">
                  <button
                    className="text-xl"
                    onClick={() => updateTravelerCount(type, -1)}
                    aria-label={`Decrease ${label}`}
                  >
                    -
                  </button>
                  <input
                    type="text"
                    className={`w-7 md:w-16 text-center ${
                      isDisabled ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    value={travelerData[type]}
                    onChange={(e) => handleInputChange(type, e.target.value)}
                    aria-label={`Number of ${label}`}
                    disabled={isDisabled}
                  />
                  <button
                    className="text-xl text-green-500"
                    onClick={() => updateTravelerCount(type, 1)}
                    aria-label={`Increase ${label}`}
                  >
                    +
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default TravelarCounter;